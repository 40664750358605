<script lang="ts" setup> 

    const props = defineProps({ 
        blok: Object 
    })

    const richtext = (value:any) => renderRichText(value) 

</script>

<template>
   <div v-editable="blok" class="grid grid-col-1" :class="[blok?.theme || 'dark']">
        <div class="flex flex-col gap-6 lg:gap-8">
      
            <div v-if="blok?.heading || blok?.subheading || blok?.richtext && richtext(blok?.richtext)" class="flex flex-col gap-4 justify-center items-center text-center max-w-[900px] mx-auto">
                <div v-if="blok?.heading || blok?.subheading" class="flex flex-col gap-2 justify-center items-center text-center">
                    <component
                        v-if="blok && blok?.heading" 
                        :is="`headings-${blok?.heading_tag || 'h3'}`" 
                        :value="blok?.heading"
                        :classes="blok?.heading_style || 'h2'" 
                    />
                    <component
                        v-if="blok && blok?.subheading" 
                        :is="`headings-${blok?.subheading_tag || 'h4'}`" 
                        :value="blok?.subheading"
                        :classes="blok?.subheading_style || 'h3'"
                    />
                </div>
                <div v-if="blok?.richtext && richtext(blok?.richtext)" v-html="richtext(blok?.richtext)" class="flex flex-col gap-2 | font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-normal text-white-50/75 dark:text-nevada-500"/>
            </div>

            <div 
                v-if="blok?.columns && blok?.columns.length > 0"
                class="grid gap-4"
                :class="[blok?.grid_columns_mobile, blok?.grid_columns_tablet, blok?.grid_columns_desktop]"
            >
                <div v-for="column in blok.columns" :key="column._uid" v-editable="column" class="grid grid-col-1">
                    <div class="flex flex-col gap-4">
                        <NuxtImg
                            v-if="column?.image && column?.image?.filename"
                            provider="storyblok"
                            width="600"
                            quality="100"
                            class="w-full h-auto mx-auto rounded-1"
                            :src="column.image.filename"
                        />
                        <div class="flex flex-col gap-1 justify-center items-center text-center">
                            <h3 v-if="column?.heading" v-html="column.heading" class="font-hass-display text-h5_mobile md:text-h5_tablet lg:text-h5_desktop font-medium text-elephant-950 text-center"/>
                            <h4 v-if="column?.subheading" v-html="column.subheading" class="font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-normal text-nevada-500 text-center"/>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="blok?.buttons && blok?.buttons.length > 0" class="flex flex-wrap gap-2 justify-center items-center text-center">
                <StoryblokComponent v-for="button in blok.buttons" :key="button._uid" :blok="button" />
            </div>

        </div>
   </div>
</template>